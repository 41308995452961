define("ember-ajax/-private/promise", ["exports"], function (exports) {
    "use strict";

    var _createClass = (function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    })();

    var _get = function get(_x, _x2, _x3) {
        var _again = true;

        _function: while (_again) {
            var object = _x,
                property = _x2,
                receiver = _x3;
            _again = false;
            if (object === null) object = Function.prototype;var desc = Object.getOwnPropertyDescriptor(object, property);if (desc === undefined) {
                var parent = Object.getPrototypeOf(object);if (parent === null) {
                    return undefined;
                } else {
                    _x = parent;
                    _x2 = property;
                    _x3 = receiver;
                    _again = true;
                    desc = parent = undefined;
                    continue _function;
                }
            } else if ("value" in desc) {
                return desc.value;
            } else {
                var getter = desc.get;if (getter === undefined) {
                    return undefined;
                }return getter.call(receiver);
            }
        }
    };

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    /**
     * AJAX Promise
     *
     * Sub-class of RSVP Promise that passes the XHR property on to the
     * child promise
     *
     * @extends RSVP.Promise
     * @private
     */
    var AJAXPromise = (function (_Ember$RSVP$Promise) {
        _inherits(AJAXPromise, _Ember$RSVP$Promise);

        // NOTE: Only necessary due to broken definition of RSVP.Promise
        // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/26640
        function AJAXPromise(executor, label) {
            _classCallCheck(this, AJAXPromise);

            // @ts-ignore
            return _possibleConstructorReturn(this, (AJAXPromise.__proto__ || Object.getPrototypeOf(AJAXPromise)).call(this, executor, label));
        }
        /**
         * Overriding `.then` to add XHR to child promise
         */

        _createClass(AJAXPromise, [{
            key: 'then',
            value: function then(onFulfilled, onRejected, label) {
                var child = _get(AJAXPromise.prototype.__proto__ || Object.getPrototypeOf(AJAXPromise.prototype), 'then', this).call(this, onFulfilled, onRejected, label);
                child.xhr = this.xhr;
                return child;
            }
        }]);

        return AJAXPromise;
    })(Ember.RSVP.Promise);

    exports["default"] = AJAXPromise;
});