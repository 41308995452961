define("shared-goodcity/controllers/messages_base", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  exports["default"] = _ember["default"].Controller.extend({
    messageLinkConvertor: _ember["default"].inject.service(),

    body: "",
    offerController: _ember["default"].inject.controller("offer"),
    messagesUtil: _ember["default"].inject.service("messages"),
    isPrivate: false,
    inProgress: false,
    offer: _ember["default"].computed.alias("offerController.model"),
    sortProperties: ["createdAt:asc"],
    sortedElements: _ember["default"].computed.sort("messagesAndVersions", "sortProperties"),
    isItemThread: _ember["default"].computed.notEmpty("item"),

    autoMarkAsRead: _ember["default"].on('init', _ember["default"].observer('isActive', 'messages.[]', 'messages.@each.state', function () {
      if (this.get('isActive')) {
        _ember["default"].run.debounce(this, this.markConversationAsRead, 1500);
      }
    })),

    disabled: _ember["default"].computed("offer.isCancelled", "item.isDraft", function () {
      return this.get("offer.isCancelled") || this.get("item.isDraft");
    }),

    groupedElements: _ember["default"].computed("sortedElements.[]", function () {
      return this.groupBy(this.get("sortedElements"), "createdDate");
    }),

    allMessages: _ember["default"].computed(function () {
      return this.store.peekAll("message");
    }),

    messages: _ember["default"].computed("allMessages.[]", "offer", "item", function () {
      var _this = this;

      var messages = this.get("allMessages");
      messages = this.get("isItemThread") ? messages.filterBy("itemId", this.get("item.id")) : messages.filterBy("offerId", this.get("offer.id")).filterBy("item", null);
      return messages.filter(function (m) {
        return Boolean(m.get("isPrivate")) === _this.get("isPrivate");
      });
    }),

    messagesAndVersions: _ember["default"].computed("messages.[]", "itemVersions", "packageVersions", "offerVersions", function () {
      var messages = this.get("messages").toArray();
      var itemVersions = this.get("itemVersions").toArray();
      var packageVersions = this.get("packageVersions").toArray();
      var offerVersions = this.get("offerVersions").toArray();
      return messages.concat(itemVersions, packageVersions, offerVersions);
    }),

    itemVersions: _ember["default"].computed("item.id", "allVersions.[]", "isItemThread", function () {
      if (!this.get("isItemThread")) {
        return [];
      }
      var itemId = parseInt(this.get("item.id"), 10);
      return this.get("allVersions").filterBy("itemId", itemId).filterBy("itemType", "Item");
    }),

    packageVersions: _ember["default"].computed("item.packages", "allVersions.[]", "isItemThread", function () {
      if (!this.get("isItemThread")) {
        return [];
      }
      var packageIds = (this.get("item.packages") || []).mapBy("id");
      return this.get("allVersions").filterBy("itemType", "Package").filter(function (log) {
        return packageIds.indexOf(String(log.get("itemId"))) >= 0 && ["received", "missing"].indexOf(log.get("state")) >= 0;
      });
    }),

    allVersions: _ember["default"].computed(function () {
      return this.get("store").peekAll("version");
    }),

    offerVersions: _ember["default"].computed("allVersions.[]", "offer.id", "isItemThread", function () {
      if (this.get("isItemThread")) {
        return [];
      }
      var offerId = parseInt(this.get("offer.id"), 10);
      return this.get("allVersions").filterBy("itemType", "Offer").filterBy("itemId", offerId);
    }),

    groupBy: function groupBy(content, key) {
      var result = [];
      var object, value;

      content.forEach(function (item) {
        value = item.get ? item.get(key) : item[key];
        object = result.findBy("value", value);
        if (!object) {
          object = {
            value: value,
            items: []
          };
          result.push(object);
        }
        return object.items.push(item);
      });
      return result.getEach("items");
    },

    markConversationAsRead: function markConversationAsRead() {
      var _this2 = this;

      this.get("messages").filterBy("state", "unread").forEach(function (m) {
        return _this2.get("messagesUtil").markRead(m);
      });
    },

    actions: {
      sendMessage: function sendMessage() {
        var _this3 = this;

        // To hide soft keyboard
        _ember["default"].$("textarea").trigger("blur");

        this.set("inProgress", true);
        var values = this.getProperties("body", "offer", "item", "isPrivate");
        values.itemId = this.get("item.id");
        values.offerId = this.get("offer.id");
        values.createdAt = new Date();
        values.sender = this.store.peekRecord("user", this.get("session.currentUser.id"));
        this.get("messageLinkConvertor").convert(values);
        var message = this.store.createRecord("message", values);
        message.save().then(function () {
          _this3.set("body", "");
        })["catch"](function (error) {
          _this3.store.unloadRecord(message);
          throw error;
        })["finally"](function () {
          return _this3.set("inProgress", false);
        });

        _ember["default"].$("body").animate({ scrollTop: _ember["default"].$(document).height() }, 1000);
      }
    }
  });
});