define('ember-ajax/request', ['exports', 'ember-ajax/ajax-request'], function (exports, _emberAjaxAjaxRequest) {
  'use strict';

  exports['default'] = request;

  /**
   * Helper function that allows you to use the default `ember-ajax` to make
   * requests without using the service.
   *
   * @public
   */

  function request(url, options) {
    var ajax = new _emberAjaxAjaxRequest['default']();
    return ajax.request(url, options);
  }
});