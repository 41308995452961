define('ember-rollbar-client/services/rollbar', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  exports['default'] = Ember.Service.extend({
    enabled: true,
    currentUser: null,

    notifier: Ember.computed(function () {
      return this.rollbarClient();
    }),

    rollbarClient: function rollbarClient() {
      var customConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var appConfig = this._appConfig().emberRollbarClient || {};
      var config = Ember.assign(this._defaultConfig(), appConfig, customConfig);

      return new _rollbar['default'](config);
    },

    // Observers

    currentUserChanged: Ember.observer('currentUser', function () {
      return this.get('notifier').configure({ payload: { person: this.get('currentUser') } });
    }),

    enabledChanged: Ember.observer('enabled', function () {
      return this.get('notifier').configure({ enabled: this.get('enabled') });
    }),

    // Notifications

    critical: function critical(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').critical(message, data);
    },
    error: function error(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').error(message, data);
    },
    warning: function warning(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').warning(message, data);
    },
    info: function info(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').info(message, data);
    },
    debug: function debug(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').debug(message, data);
    },

    // Private

    _defaultConfig: function _defaultConfig() {
      return {
        enabled: this.get('enabled'),
        accessToken: '',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: this._environment(),
          client: {
            javascript: {
              source_map_enabled: true,
              // code_version: "some version string, such as a version number or git sha",
              // Optionally have Rollbar guess which frames the error was thrown from
              // when the browser does not provide line and column numbers.
              guess_uncaught_frames: true
            }
          }
        }
      };
    },
    _environment: function _environment() {
      return this._appConfig().environment;
    },
    _appConfig: function _appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }
  });
});