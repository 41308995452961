define('ember-smart-banner/utils/storage', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.setItem = setItem;
  exports.getItem = getItem;

  var namespace = 'ember-smart-banner';

  var Promise = _ember['default'].RSVP.Promise;

  function setItem(key, value) {
    return storageOperation('setItem', _namespacedKey(key), JSON.stringify(value));
  }

  function getItem(key) {
    return storageOperation('getItem', _namespacedKey(key)).then(safelyParseJSON);
  }

  function storageOperation(methodName) {
    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    var storage = getStorage();
    return Promise.resolve().then(function () {
      if (storage) {
        return storage[methodName].apply(storage, args);
      } else {
        throw new Error('No local storage capability');
      }
    });
  }

  function getStorage() {
    if (typeof localforage !== 'undefined') {
      return localforage;
    }

    if (typeof localStorage !== 'undefined') {
      return localStorage;
    }
  }

  function _namespacedKey(keyName) {
    return namespace + '.' + keyName;
  }

  function safelyParseJSON(json) {
    var parsed = undefined;

    try {
      parsed = JSON.parse(json);
    } catch (e) {
      // catch error;
    }

    return parsed; // could be undefined
  }
});