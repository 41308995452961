define('ember-smart-banner/components/smart-banner', ['exports', 'ember', 'ember-smart-banner/templates/components/smart-banner', 'ember-getowner-polyfill', 'ember-smart-banner/utils/banner-storage'], function (exports, _ember, _emberSmartBannerTemplatesComponentsSmartBanner, _emberGetownerPolyfill, _emberSmartBannerUtilsBannerStorage) {
  /* global window, navigator */

  'use strict';

  var computed = _ember['default'].computed;
  var getDayClosed = _emberSmartBannerUtilsBannerStorage['default'].getDayClosed;
  var getDayVisited = _emberSmartBannerUtilsBannerStorage['default'].getDayVisited;
  var setDayClosed = _emberSmartBannerUtilsBannerStorage['default'].setDayClosed;
  var setDayVisited = _emberSmartBannerUtilsBannerStorage['default'].setDayVisited;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberSmartBannerTemplatesComponentsSmartBanner['default'],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.errors = [];

      getDayClosed().then(function (result) {
        _this.set('dayClosed', result);
      })['catch'](function () {
        _this.set('showBanner', false);
      });

      getDayVisited().then(function (result) {
        _this.set('dayVisited', result);
      })['catch'](function () {
        _this.set('showBanner', false);
      });
    },

    dayClosed: null,

    dayVisited: null,

    classNames: ['ember-smart-banner'],
    classNameBindings: ['iconUrl:has-icon'],
    // http://discuss.emberjs.com/t/best-practices-accessing-app-config-from-addon-code/7006/16
    config: computed(function () {
      return (0, _emberGetownerPolyfill['default'])(this).resolveRegistration('config:environment').emberSmartBanner;
    }),

    title: computed.or('titleIOS', 'titleAndroid', 'config.title', 'bannerDefaults.title'),
    description: computed.or('descriptionIOS', 'descriptionAndroid', 'config.description', 'bannerDefaults.description'),
    linkText: computed.or('linkTextIOS', 'linkTextAndroid', 'config.linkText', 'bannerDefaults.linkText'),
    iconUrl: computed.or('config.iconUrl', 'bannerDefaults.iconUrl'),
    showBanner: computed.and('bannerOpen', 'supportsOS', 'afterCloseBool', 'afterVisitBool'), // Set showBanner to true to always show
    link: computed.or('displayAppStoreLink', 'displayMarketLink'),

    userAgent: computed(function () {
      return navigator.userAgent || navigator.vendor || window.opera;
    }),

    supportsOS: computed.or('supportsIOS', 'supportsAndroid'),
    supportsIOS: computed.and('iOS', 'appIdIOS'),
    supportsAndroid: computed.and('android', 'appIdAndroid'),

    iOS: computed('userAgent', function () {
      var userAgent = this.get('userAgent');
      return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i);
    }),

    android: computed('userAgent', function () {
      var userAgent = this.get('userAgent');
      return userAgent.match(/Android/i);
    }),

    titleIOS: computed.and('iOS', 'config.titleIOS'),
    descriptionIOS: computed.and('iOS', 'config.descriptionIOS'),
    linkTextIOS: computed.and('iOS', 'config.linkTextIOS'),
    appStoreLanguage: computed.or('config.appStoreLanguage', 'bannerDefaults.appStoreLanguage'),
    appIdIOS: computed.or('config.appIdIOS', 'bannerDefaults.appIdIOS'),
    appStoreLink: computed(function () {
      return this.get('bannerDefaults.appStoreLinkBase') + '/' + this.get('appStoreLanguage') + ('/app/id' + this.get('appIdIOS'));
    }),

    displayAppStoreLink: computed.and('supportsIOS', 'appStoreLink'),

    titleAndroid: computed.and('android', 'config.titleAndroid'),
    descriptionAndroid: computed.and('android', 'config.descriptionAndroid'),
    linkTextAndroid: computed.and('android', 'config.linkTextAndroid'),
    appIdAndroid: computed.or('config.appIdAndroid', 'bannerDefaults.appIdAndroid'),
    marketLink: computed(function () {
      return '' + this.get('bannerDefaults.marketLinkBase') + this.get('appIdAndroid');
    }),

    displayMarketLink: computed.and('supportsAndroid', 'marketLink'),

    bannerDefaults: {
      appStoreLinkBase: 'https://itunes.apple.com',
      appStoreLanguage: 'en',
      marketLinkBase: 'market://details?id=',
      title: 'Download our App',
      linkText: 'View',
      iconUrl: 'http://icons.iconarchive.com/icons/wineass/ios7-redesign/512/Appstore-icon.png'
    },

    bannerClosed: false,
    bannerOpen: computed.not('bannerClosed'),

    actions: {
      openLink: function openLink() {
        this.set('bannerClosed', true);
        setDayVisited();
        var visitFn = _ember['default'].getWithDefault(this, 'attrs.onvisit', _ember['default'].K);
        visitFn();
      },

      closeBanner: function closeBanner(e) {
        e.preventDefault();
        this.set('bannerClosed', true);
        setDayClosed();
        var closeFn = _ember['default'].getWithDefault(this, 'attrs.onclose', _ember['default'].K);
        closeFn();
      }
    },

    // Number of days after close to wait to show banner again
    // Set to true if always show banner after clicking the close button
    // Set false if the banner never shows again after clicking close
    openAfterClose: computed.reads('config.openAfterClose'),

    // Number of days after visit to wait to show banner again
    // Set to true if always show banner after clicking the visit button
    // Set false if the banner never shows again after clicking visit
    openAfterVisit: computed.reads('config.openAfterVisit'),

    neverShowAfterClose: computed.equal('openAfterClose', false),
    neverClosed: computedIsNaN('daysSinceClose'),
    recentlyClosed: computed.not('neverClosed'),
    restrictAfterClose: restrictMacro('openAfterClose'),

    neverShowAfterVisit: computed.equal('openAfterVisit', false),
    neverVisited: computedIsNaN('daysSinceVisit'),
    recentlyVisited: computed.not('neverVisited'),
    restrictAfterVisit: restrictMacro('openAfterVisit'),

    afterCloseBool: computed('daysSinceClose', 'openAfterClose', function () {
      var wasRecentlyClosed = this.get('recentlyClosed');
      var neverShowAfterClose = this.get('neverShowAfterClose');
      var restrictAfterClose = this.get('restrictAfterClose');

      if (neverShowAfterClose && wasRecentlyClosed) {
        // never show if { openAfterClose: false } && has been closed
        return false;
      }

      if (restrictAfterClose && wasRecentlyClosed) {
        // if { openAfterClose: isValidNumber }
        return this.gteDependentKeys('daysSinceClose', 'openAfterClose');
      }

      return true;
    }),

    afterVisitBool: computed('daysSinceVisit', 'openAfterVisit', function () {
      var wasRecentlyVisited = this.get('recentlyVisited');
      var neverShowAfterVisit = this.get('neverShowAfterVisit');
      var restrictAfterVisit = this.get('restrictAfterVisit');

      if (neverShowAfterVisit && wasRecentlyVisited) {
        // never show if { openAfterVisit: false } && has been visited
        return false;
      }

      if (restrictAfterVisit && wasRecentlyVisited) {
        // if { openAfterVisit: isValidNumber }
        return this.gteDependentKeys('daysSinceVisit', 'openAfterVisit');
      }

      return true;
    }),

    gteDependentKeys: function gteDependentKeys(firstKey, secondKey) {
      return this.get(firstKey) >= this.get(secondKey);
    },

    // Returns NaN if no localstorage data has been set
    daysSinceClose: computed('dayClosed', function () {
      var dayClosed = this.get('dayClosed');
      var timeSinceClosed = new Date() - Date.parse(dayClosed);
      return Math.floor(timeSinceClosed / (24 * 60 * 60 * 1000)); // Convert ms to days
    }),

    // Returns NaN if no localstorage data has been set
    daysSinceVisit: computed('dayVisited', function () {
      var dayVisited = this.get('dayVisited');
      var timeSinceVisited = new Date() - Date.parse(dayVisited);
      return Math.floor(timeSinceVisited / (24 * 60 * 60 * 1000)); // Convert ms to days
    })

  });

  //https://github.com/jasny/jquery.smartbanner/blob/master/jquery.smartbanner.js
  function computedIsNaN(depKey) {
    return computed(depKey, function () {
      return isNaN(this.get(depKey));
    });
  }

  function restrictMacro(delayKey) {
    return computed(delayKey, function () {
      var openDelay = this.get(delayKey);
      var isValidNumber = isNaN(parseInt(openDelay, 10)) === false;
      return isValidNumber;
    });
  }
});