define('ember-smart-banner/utils/banner-storage', ['exports', 'ember-smart-banner/utils/storage'], function (exports, _emberSmartBannerUtilsStorage) {
  'use strict';

  exports.getDayClosed = getDayClosed;
  exports.setDayClosed = setDayClosed;
  exports.getDayVisited = getDayVisited;
  exports.setDayVisited = setDayVisited;

  var setItem = _emberSmartBannerUtilsStorage['default'].setItem;
  var getItem = _emberSmartBannerUtilsStorage['default'].getItem;

  function _setTimeStamp(key) {
    var now = new Date();
    return setItem(key, now);
  }

  function getDayClosed() {
    return getItem('lastDayClosed');
  }

  function setDayClosed() {
    return _setTimeStamp('lastDayClosed');
  }

  function getDayVisited() {
    return getItem('lastDayVisited');
  }

  function setDayVisited() {
    return _setTimeStamp('lastDayVisited');
  }
});