define('ember-cli-cordova/initializers/in-app-livereload', ['exports', 'ember-cli-cordova/utils/redirect'], function (exports, _emberCliCordovaUtilsRedirect) {
  'use strict';

  var initialize = function initialize(app, config) {
    var url = config.cordova.emberUrl || 'http://localhost:4200';
    return (0, _emberCliCordovaUtilsRedirect['default'])(url);
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'cordova:in-app-livereload',
    initialize: initialize
  };
});